<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="310px"
    scrollable
    content-class="content-scroll"
  >
    <v-date-picker
      v-model="fecha"
      scrollable
      first-day-of-week="1"
      class="date-task"
      :disabled="loading"
      :max="maxFecha"
    >
    <div class="d-flex flex-column">
      <span class="blue-grey--text font-weight-medium caption pb-3">
        <v-icon size="22" color="info">mdi-information-outline</v-icon>
        Seleccione una fecha para continuar
      </span>
      <div class="d-flex justify-center">
        <v-btn
          tile
          depressed
          small
          color="blue-grey lighten-5"
          class="px-2 mx-2"
          :disabled="loading"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          small
          depressed
          color="primary"   
          :loading="loading" 
          @click="sendTaskVisita"      
        >
          <v-icon size="20" dark>mdi-check</v-icon>
          <span class="px-2">Aceptar</span>
        </v-btn>
      </div>
    </div>
    </v-date-picker>
  </v-dialog>
</template>
<script>
import moment from 'moment'
import { taskVisita } from '@/services/tasks.js'
moment.locale('es')

export default {
  name:'ModalFecha',
  props:{
    value: Boolean,
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      fecha: moment().format('YYYY-MM-DD'),
      maxFecha: moment().format('YYYY-MM-DD'),
         
    }
  },
  computed: {
    formatFecha () {
      return moment(this.fecha).format("DD-MM-YYYY")
    },
  },
  watch: {
    showModal (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.showModal = val
    },
  },
  methods: {
    cerrar() {
      this.showModal = false;
    },
    async sendTaskVisita() {
      this.loading = true
      try {
        const { message } = await taskVisita({ fecha: this.formatFecha })
        this.$root.$showAlert(message, 'success')       
        this.$emit('updateLogs') 
      } catch (error) {
        this.$root.$showAlert(
          'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
          'error',
        )
      } finally {
        this.loading = false
        this.showModal = false
      }
    }
  },
}
</script>
<style>
.date-task .v-date-picker-table {
  height: 210px !important;
}
</style>
